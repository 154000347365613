import { GetStaticPaths, GetStaticPathsContext, GetStaticProps } from "next";
import { dehydrate, QueryClient } from "@tanstack/react-query";

import { getPage, getPagesOfType } from "@src/lib/api";
import getFooterSection from "@src/services/contentful/footer/getFooterMenu";
import { getHeaderMenuGraphQL } from "@src/services/contentful/mainHeader/getMainHeader";

import { getSiteSettingsGql } from "@src/services/contentful/settings/settings";
import { PageProps } from ".";
import { getFaqSchemaGql } from "@src/services/contentful/faqSchema/faqSchema";
export { default } from "@src/pages/page";


export async function getPageStaticProps(data: any, pageType: string): Promise<{props: PageProps}> {
  // console.log("getStaticProps", data);
  const { params } = data;

  const preview = data?.preview || false;

  const queryClient = new QueryClient();

  const settings = await getSiteSettingsGql({
    queryClient,
    preview,
  });

  // http://localhost:3000/blog/hmo-vs-ppo-types-of-medicare-advantage-plans
  const faqEntries = await getFaqSchemaGql({
    queryClient,
    preview,
    slug: params?.slug?.toString().toLowerCase(),
  });


  let page = await getPage({
    slug: params?.slug?.toString().toLowerCase(),
    preview,
    pageContentType: pageType,
  });

  if (!page) {
    page = await getPage({
      slug: "404",
      preview,
      pageContentType: "pageErrorPage",
    });
  }

  //console.log("page: ", safeStringify(page));

  let footerMenu = await getFooterSection({
    query: {
      limit: 1,
      include: 5,
      select: "sys.id,sys.contentType,sys.type,fields",
    },
    preview,
  });

  //console.log("footerMenu: ", JSON.stringify(footerMenu));
  //console.log("page: ", page);

  // let header = await getHeaderMenu({
  //   query: {
  //     includes: 10,
  //   },
  //   preview: data?.preview || false,
  // });

  let headergql = await getHeaderMenuGraphQL({
    preview: data?.preview || false,
  });

  // let settings = await getSiteSettings({
  //   preview: data?.preview || false,
  // });

  // footerMenu = deepOmit(footerMenu, [
  //   "metadata",
  //   "environment",
  //   "createdAt",
  //   "updatedAt",
  //   "revision",
  //   "space",
  //   "locale",
  // ]);

  // page = deepOmit(page, [
  //   "metadata",
  //   "environment",
  //   "createdAt",
  //   "updatedAt",
  //   "revision",
  //   "space",
  //   "locale",
  // ]);

  // header = deepOmit(header, [
  //   "metadata",
  //   "environment",
  //   "createdAt",
  //   "updatedAt",
  //   "revision",
  //   "space",
  //   "locale",
  // ]);

  const props: PageProps = {
    settings,
    page,
    footerMenu,
    consentVisible: true,
    preview: data?.preview || false,
    header: headergql,
    faqEntries,
  };

  //console.log("page: ", removeKeys(page, ["sys", "metadata"]));
  return {
    props
  };
}

export async function getPageStaticPaths(
  data: any,
  pageType: string,
  path?: string
) {
  //console.log("genericPages:[slug]", data);
  //console.log("CONTENTFUL_ENVIRONMENT", process.env.CONTENTFUL_ENVIRONMENT);
  // const entries: EntryCollection<Pick<IPageFields, "slug">> = await getClient(
  //   false
  // ).getEntries({
  //   content_type: "pageGenericPage",
  //   //select: "slug",
  // });
  if (!path) {
    path = "";
  }

  const entries = await getPagesOfType({
    pageContentType: pageType,
  });

  //console.log("entries: ", entries);
  // entries
  //   .filter(({ sys }) => sys?.contentType?.sys?.id === "pageGenericPage")
  //   .map(({ fields }) =>
  //     console.log("[slug]:entries", JSON.stringify(sys?.contentType?.sys?.id))
  //   );
  // entries
  //   .filter(
  //     ({ fields }) =>
  //       fields?.content?.sys?.contentType?.sys?.id === "pageGenericPage"
  //   )
  //   .map(({ fields }) =>
  //     console.log("[slug]:entries", fields?.content?.sys?.contentType?.sys?.id)
  //   );
  const paths = entries
    .filter(
      ({ fields }: any) =>
        fields?.content?.sys?.contentType?.sys?.id === pageType
    )
    .map(({ fields }: any) => `${path}/${fields?.slug.toLowerCase()}`);

  return {
    paths,
    fallback: true,
  };
}

export const getStaticProps: GetStaticProps = async (data) => {
  return await getPageStaticProps(data, "pageGenericPage") as any;
};

export const getStaticPaths: GetStaticPaths = async (data) => {
  return await getPageStaticPaths(data, "pageGenericPage");
};
