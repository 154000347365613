// @ts-nocheck
import * as Types from '../../../../lib/__generated/graphql.types';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { myFetcher } from '@src/lib/fetchConfig.dtc';
export type FaqFragment = { __typename?: 'Faq', entriesCollection?: { __typename?: 'FaqEntriesCollection', items: Array<{ __typename?: 'FaqEntry', question?: string | null, sys: { __typename?: 'Sys', id: string }, answer?: { __typename?: 'FaqEntryAnswer', json: any } | null } | null> } | null };

export type BlogPostFragment = { __typename?: 'PageBlogPost', contentCollection?: { __typename?: 'PageBlogPostContentCollection', items: Array<(
      { __typename?: 'Faq' }
      & FaqFragment
    ) | { __typename?: 'Section' } | null> } | null };

export type GenericPageFragment = { __typename?: 'PageGenericPage', contentCollection?: { __typename?: 'PageGenericPageContentCollection', items: Array<(
      { __typename?: 'Faq' }
      & FaqFragment
    ) | { __typename?: 'MainSiteHero' } | { __typename?: 'NascarLogos' } | { __typename?: 'Section' } | null> } | null };

export type FaqSchemaQueryVariables = Types.Exact<{
  slug?: Types.InputMaybe<Types.Scalars['String']['input']>;
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type FaqSchemaQuery = { __typename?: 'Query', pageCollection?: { __typename: 'PageCollection', items: Array<{ __typename: 'Page', title?: string | null, content?: { __typename?: 'FourZeroFour' } | { __typename?: 'PageBlogIndex' } | (
        { __typename?: 'PageBlogPost' }
        & BlogPostFragment
      ) | { __typename?: 'PageErrorPage' } | (
        { __typename?: 'PageGenericPage' }
        & GenericPageFragment
      ) | { __typename?: 'PageLandingPage' } | null } | null> } | null };

export const FaqFragmentDoc = `
    fragment FAQ on Faq {
  entriesCollection(preview: $preview) {
    items {
      sys {
        id
      }
      question
      answer {
        json
      }
    }
  }
}
    `;
export const BlogPostFragmentDoc = `
    fragment BlogPost on PageBlogPost {
  contentCollection(preview: $preview) {
    items {
      ...FAQ
    }
  }
}
    `;
export const GenericPageFragmentDoc = `
    fragment GenericPage on PageGenericPage {
  contentCollection(preview: $preview) {
    items {
      ...FAQ
    }
  }
}
    `;
export const FaqSchemaDocument = `
    query FAQSchema($slug: String, $preview: Boolean) {
  pageCollection(preview: $preview, where: {slug: $slug}, limit: 1) {
    __typename
    items {
      __typename
      title
      content {
        ...BlogPost
        ...GenericPage
      }
    }
  }
}
    ${BlogPostFragmentDoc}
${FaqFragmentDoc}
${GenericPageFragmentDoc}`;
export const useFaqSchemaQuery = <
      TData = FaqSchemaQuery,
      TError = unknown
    >(
      variables?: FaqSchemaQueryVariables,
      options?: UseQueryOptions<FaqSchemaQuery, TError, TData>
    ) =>
    useQuery<FaqSchemaQuery, TError, TData>(
      variables === undefined ? ['FAQSchema'] : ['FAQSchema', variables],
      myFetcher<FaqSchemaQuery, FaqSchemaQueryVariables>(FaqSchemaDocument, variables),
      options
    );

useFaqSchemaQuery.getKey = (variables?: FaqSchemaQueryVariables) => variables === undefined ? ['FAQSchema'] : ['FAQSchema', variables];
;

useFaqSchemaQuery.fetcher = (variables?: FaqSchemaQueryVariables, options?: RequestInit['headers']) => myFetcher<FaqSchemaQuery, FaqSchemaQueryVariables>(FaqSchemaDocument, variables, options);