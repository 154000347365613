import deepOmit from "src/lib/api-util/deepOmit";
import getClient from "src/services/contentful/contentful";

import { ISectionFields } from "src/types/generated/contentful";
import { resolveExternalEntries } from "../externalResources";

type GetFooterEntries = {
  query:
    | {
        order?: string;
        limit?: number;
        skip?: number;
        select?: string;
      }
    | { [key: string]: string | number | boolean };
  preview?: boolean;
};
// export default async function getFooterMenu({
//   query,
//   preview,
// }: GetFooterEntries): Promise<FooterParsed | null> {
//   const entries = await getClient(preview).getEntries<IFooterFields>({
//     content_type: "footer",
//     ...query,
//   });

//   return entries?.items.length > 0 ? footerParser(entries.items[0]) : null;
// }

export default async function getFooterSection({
  query,
  preview,
}: GetFooterEntries): Promise<any | null> {
  const q = {
    content_type: "section",
    ...{
      ...query,
      ...{ "fields.technicalId": "shd-main-footer" },
    },
  };

  let entries = await getClient({ preview }).getEntries(q);

  entries = await resolveExternalEntries(entries, preview || false);
  let footerMenu = entries.items[0] || null;

  if (footerMenu) {
    footerMenu = deepOmit(footerMenu);
  }

  return footerMenu;
  // return entries?.items.length > 0 ? footerParser(entries.items[0]) : null;
}
