import { fetchConfig as fetchConfig_Schemas } from "./fetchConfig.schemas";

export const myFetcher = async <TData, TVariables>(
  query: string,
  variables?: any,
  options?: RequestInit["headers"]
) => {
  const endpoint = variables?.spaceId
    ? getEndpointBySpaceId(variables?.spaceId)
    : fetchConfig.endpoint;

  const reqParams = variables?.spaceId
    ? getAccessTokenBySpaceId(variables?.spaceId, variables.preview)
    : fetchConfig.params;

  if (!variables?.spaceId && variables?.preview) {
    reqParams.headers = {
      ...reqParams.headers,
      Authorization: `Bearer ${process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN}`,
    };
  }

  const res = await fetch(endpoint, {
    method: "POST",
    ...reqParams,
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await res.json();

  if (json.errors) {
    console.error(json.errors);
    throw new Error("Failed to fetch API");
  }

  return json.data as TData;
};

const getEndpointBySpaceId = (spaceId: string) => {
  // schemas space
  return `https://graphql.contentful.com/content/v1/spaces/${spaceId}`;
};

const getAccessTokenBySpaceId = (spaceId: string, preview: boolean) => {
  // schemas space
  return fetchConfig_Schemas.params;
};

export const fetchConfig = {
  endpoint: `https://graphql.contentful.com/content/v1/spaces/${process.env["CONTENTFUL_SPACE_ID"]}/environments/${process.env["CONTENTFUL_ENVIRONMENT"]}`,
  params: {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
    },
  },
};

// starter
// export const fetchConfig_Schemas = {
//   endpoint: `https://graphql.contentful.com/content/v1/spaces/i3820ws8fk9m`,
//   params: {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN_i3820ws8fk9m}`,
//     },
//   },
// };
