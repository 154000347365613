import isObject from "lodash/isObject";
import keyBy from "lodash/keyBy";
import transform from "lodash/transform";

const KEYS_TO_OMIT = ["environment", "revision", "space", "locale"];

/**
 * Runs through an object and cleans any parts you don't want
 * @param obj Any json Object
 * @param keysToOmit An array of keys you want to remove from the object
 * @returns
 */
const deepOmit = (obj: any) => {
  var keysToOmitIndex = keyBy(
    Array.isArray(KEYS_TO_OMIT) ? KEYS_TO_OMIT : [KEYS_TO_OMIT]
  ); // create an index object of the keys that should be omitted

  function omitFromObject(obj: any) {
    // the inner function which will be called recursivley
    return transform(obj, (result: any, value: any, key: any) => {
      // transform to a new object
      if (key in keysToOmitIndex) {
        // if the key is in the index skip it
        return;
      }

      result[key] = isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
    });
  }

  return omitFromObject(obj); // return the inner function result
};

export default deepOmit;
