import filter from "lodash/filter";
import getClient from "src/services/contentful/contentful";
import { IHeaderFields } from "src/types/generated/contentful";

function parseHeader({ fields }: any): IHeaderFields {
  return {
    title: fields.title,
    content: fields.content,
    menuItems: fields.menuItems,
  };
}

type GetHeaderEntries = {
  query:
    | {
        order?: string;
        limit?: number;
        skip?: number;
        select?: string;
      }
    | { [key: string]: string | number | boolean };
  preview?: boolean;
};
export default async function getHeaderMenu({
  query,
  preview = false,
}: GetHeaderEntries): Promise<IHeaderFields | null> {
  const entries = await getClient({ preview }).getEntries({
    content_type: "header",
    ...query,
  });

  //console.log("header entries", JSON.stringify(entries, null, 2));

  return entries?.items.length > 0 ? parseHeader(entries.items[0]) : null;
}

export const getHeaderMenuGraphQL = async ({
  preview,
}: {
  preview: boolean;
}) => {
  const query = `

  fragment menuItemFragment on MenuItem {
    sys {
      id
    }
    title
    __typename
    linkUrl
    entry(preview: $preview) {
      sys {
        id
      }
      slug
      __typename
    }
  }



fragment pageFragment on Page {

  __typename
  title
  slug
}


fragment subMenuItemFragment on SubMenuItem {
  sys {
    id
  }
  title
 __typename
}


fragment mainHeaderContentFragment on MainHeaderContent {
  sys {
    id
  }
  title
  subTitle
  __typename
}



 query($preview: Boolean){


  headerCollection(preview: $preview, where: {title: "SHD Header"}) {
  	items {
  	  title
      content: contentCollection(preview:$preview, limit:1) {
        items {
          sys {
            id
          }
        ...mainHeaderContentFragment
        }
      }
      menuItemsCollection(preview:$preview, limit:5) {
        items {
        ...menuItemFragment
        ...subMenuItemFragment
        ... pageFragment
        }
      }
  	}
  }

   subMenuItemCollection(preview: $preview) {

    items {
      sys {
      	id

   	 	}
      title
      __typename
      menuItemsCollection(limit: 10)  {
        items {
          ...subMenuItemFragment
      		...menuItemFragment
          ...pageFragment
        }
      }
    }
  }

  menuItemCollection(preview: $preview) {
    items {
    sys {
      id
    }
      title
      linkUrl
      entry(preview: $preview) {
        slug
      }

  	}
  }
}
  `;
  const fetchOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${
        process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN || ""
      }`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query,
      variables: {
        preview: true,
      },
    }),
  };

  const response = await fetch(
    `https://graphql.contentful.com/content/v1/spaces/${
      process.env.CONTENTFUL_SPACE_ID || ""
    }/environments/${process.env.CONTENTFUL_ENVIRONMENT || "staging"}`,
    fetchOptions
  ).then((response) => response.json());

  return await buildMenu(response.data);
};

const buildMenu = async (data: any): Promise<any> => {
  const menuItems: any[] = [];
  const responses = await Promise.all(
    data.headerCollection.items[0].menuItemsCollection.items.map(
      async (header: { title: string; linkUrl: string }) => {
        if (header.linkUrl) {
          return {
            title: header.title,
            linkUrl: header.linkUrl,
          };
          return;
        } else {
          const menuItem = await buildSubMenus(
            null,
            header,
            data.subMenuItemCollection.items,
            data.menuItemCollection.items
          );

          const t = menuItem[0] ? menuItem[0] : null;
          if (t) {
            return t;
          }
        }
      }
    )
  );

  //console.log("responses", JSON.stringify(responses[0], null, 2));

  responses.map((r) => {
    menuItems.push(r);
  });

  return {
    menuItems,
    content: {
      sys: data.headerCollection.items[0].content.items[0].sys,
      fields: data.headerCollection.items[0].content.items[0],
      __typename: data.headerCollection.items[0].content.items[0].__typename,
    },
  };
};
//sys.contentType.sys.id

const buildSubMenus = async (
  subMenuItems: any,
  parent: any,
  subMenuItemsCollection: any,
  menuItemsCollection: any
) => {
  const subMenuItemz: any = [];
  if (subMenuItems) {
    subMenuItems.map(async (s: any) => {
      await Promise.all(
        filter(subMenuItemsCollection, {
          sys: { id: s?.sys?.id },
        }).map(async (sbi) => {
          const item = {
            title: sbi.title,
            menuItems: await buildSubMenus(
              sbi.menuItemsCollection?.items,
              sbi,
              subMenuItemsCollection,
              menuItemsCollection
            ),
          };
          subMenuItemz.push(item);
        })
      );
    });
  } else {
    // if this is the first level of submenus
    await Promise.all(
      filter(subMenuItemsCollection, {
        sys: { id: parent?.sys?.id },
      }).map(async (sbi) => {
        subMenuItemz.push({
          title: sbi.title,
          menuItems: await buildSubMenus(
            sbi.menuItemsCollection.items,
            sbi?.sys?.id,
            subMenuItemsCollection,
            menuItemsCollection
          ),
        });
      })
    );
  }

  if (subMenuItems) {
    await subMenuItems.map(async (sbi: any) => {
      if (sbi.__typename === "Page" || sbi.__typename === "MenuItem") {
        const item: any = {
          title: sbi.title,
        };
        if (sbi.slug) {
          item.linkUrl = sbi.slug ? "/" + sbi.slug : null;
        } else {
          item.linkUrl = sbi.entry.slug ? "/" + sbi.entry.slug : null;
        }
        subMenuItemz.push(item);
      }
    });
  }

  return subMenuItemz;
};
