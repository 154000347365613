import { ContentfulClientApi, createClient } from "contentful";

//const sleep = async (ms: number) => {(r) => setTimeout(r, ms));

export const getAccessTokens = ({ spaceId }: { spaceId: string }) => {
  const accessToken = process.env[`CONTENTFUL_ACCESS_TOKEN_${spaceId}`];
  const previewAccessToken =
    process.env[`CONTENTFUL_PREVIEW_ACCESS_TOKEN_${spaceId}`];

  if (!accessToken) {
    console.error(`ACCESS_TOKEN_${spaceId} env variable not found`);
  }
  if (!previewAccessToken) {
    console.error(`PREVIEW_ACCESS_TOKEN_${spaceId} env variable not found`);
  }
  //await sleep(50);
  return {
    accessToken,
    previewAccessToken,
  };
};

const getClient = ({
  preview,
  spaceId = process.env.CONTENTFUL_SPACE_ID,
  accessToken,
  environment = process.env.CONTENTFUL_ENVIRONMENT,
}: {
  preview?: boolean;
  spaceId?: string;
  accessToken?: string;
  environment?: string;
} = {}): ContentfulClientApi<any> => {
  let pAccessToken = accessToken;
  if (!accessToken) {
    accessToken = preview
      ? process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN
      : process.env.CONTENTFUL_ACCESS_TOKEN;
  }

  const client = createClient({
    space: spaceId || "",
    accessToken: accessToken || "",
    environment: environment || "staging",
  });

  const previewClient = createClient({
    space: spaceId || "",
    accessToken: accessToken || "",
    environment: environment || "staging",
    host: "preview.contentful.com",
  });

  //await sleep(50);
  return preview ? previewClient : client;
};

export default getClient;
