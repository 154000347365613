import { useSettingsQuery } from "@src/components/molecules/Settings/__generated/Settings.generated";
import { fixImagePath } from "@src/utils/dom";
import { QueryClient } from "@tanstack/react-query";
import { ContactPoint, Organization } from "schema-dts";
import { IOrganizationFields } from "src/types/generated/contentful";
import { resolveExternalEntries } from "../externalResources";

// export const getSiteSettingsGraphql = async ({
//   preview,
// }: {
//   preview: boolean;
// }): Promise<ISettingsFields> => {
//   const query = `
//   query($preview: Boolean){

//     settingsCollection(preview:$preview) {
//       items {
//         siteTitle
//         siteDescription
//         siteLogo {
//           title
//           description
//           contentType
//           fileName
//           size
//           url
//           width
//           height
//         }
//         favicon {
//           title
//           description
//           contentType
//           fileName
//           size
//           url
//           width
//           height
//         }
//         siteLogo {
//           title
//           description
//           contentType
//           fileName
//           size
//           url
//           width
//           height
//         }
//         blogTitle
//         blogDescription

//       }
//     }

//   }  `;
//   const fetchOptions = {
//     method: "POST",
//     headers: {
//       Authorization: `Bearer ${
//         process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN || ""
//       }`,
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       query,
//       variables: {
//         preview: true,
//       },
//     }),
//   };

//   const response = await fetch(
//     `https://graphql.contentful.com/content/v1/spaces/${
//       process.env.CONTENTFUL_SPACE_ID || ""
//     }/environments/${process.env.CONTENTFUL_ENVIRONMENT || "staging"}`,
//     fetchOptions
//   ).then((response) => response.json());

//   return await response.data.settingsCollection.items[0];
// };

// export const getSiteSettings = async ({
//   preview,
//   client,
// }: {
//   preview: boolean;
//   client?: ContentfulClientApi<any>;
// }): Promise<ISettingsFields> => {
//   let c = client;
//   if (!c) {
//     c = getClient({ preview });
//   }
//   const entries = await c.getEntries({
//     content_type: "settings",
//     include: 5,
//     // select: "fields",

//     limit: 1,
//   });

//   const resolvedEntries = await resolveExternalEntries(entries);

//   const entry = resolvedEntries.items[0].fields;

//   console.log("entry", JSON.stringify(entry));

//   return entry;
// };

export const getSiteSettingsGql = async ({
  queryClient,
  preview,
}: {
  queryClient: QueryClient;
  preview: boolean;
}) => {
  // prefetch queries
  await queryClient.prefetchQuery(
    useSettingsQuery.getKey({ preview }),
    // @ts-ignore
    useSettingsQuery.fetcher({ preview })
  );

  const { settingsCollection } = await useSettingsQuery.fetcher({
    preview,
  });

  const settingsCollectionReplaced = await resolveExternalEntries(
    settingsCollection,
    preview
  );
  const settings = settingsCollectionReplaced?.items[0];

  return settings;
};

export const orgSchemaParser = (
  org: IOrganizationFields | undefined
): Organization => {
  //const nwOrg: any = { ...org };
  //nwOrg.contactPoint = [];
  //delete nwOrg.internalName;
  //delete nwOrg.contactPoints;

  //nwOrg.logo = org?.logo?.fields?.file?.url;

  // nwOrg.address = {
  //   "@type": "PostalAddress",
  //   streetAddress: org?.streetAddress,
  //   addressLocality: org?.addressLocality,
  //   addressRegion: org?.addressRegion,
  //   postalCode: org?.postalCode,
  //   addressCountry: org?.addressCountry,
  // };

  // delete nwOrg.streetAddress;
  // delete nwOrg.addressLocality;
  // delete nwOrg.addressRegion;
  // delete nwOrg.postalCode;
  // delete nwOrg.addressCountry;

  const logoUrl = org?.logo?.fields
    ? org?.logo?.fields?.file?.url
      //@ts-ignore graphql types are coming through too
    : org?.logo?.url;

  const org2: Organization = {
    "@type": "Corporation",
    logo: fixImagePath(`${logoUrl}?fm=jpg&q=100&w=2048&h=2048`),
    url: org?.url,
    // TODO:  email: org?.em,
    telephone: org?.telephone,
    name: org?.name,
    // TODO: description: settings.,
    address: {
      "@type": "PostalAddress",
      streetAddress: org?.streetAddress,
      addressLocality: org?.addressLocality,
      addressRegion: org?.addressRegion,
      postalCode: org?.postalCode,
      addressCountry: org?.addressCountry,
      contactType: "office",
    },
  };
  org2.sameAs = [];
  org2.areaServed = {
    "@type": "GeoShape",
    addressCountry: "USA",
  };

  //nwOrg.sameAs = [];
  if (org?.facebookUrl) {
    //nwOrg.sameAs.push(org.facebookUrl);
    // @ts-ignore
    org2.sameAs.push(org.facebookUrl);
    //delete nwOrg.facebookUrl;
  }
  if (org?.twitterUrl) {
    //nwOrg.sameAs.push(org.twitterUrl);
    // @ts-ignore
    org2.sameAs.push(org.twitterUrl);
    //delete nwOrg.twitterUrl;
  }
  if (org?.instagramUrl) {
    //nwOrg.sameAs.push(org.instagramUrl);
    // @ts-ignore
    org2.sameAs.push(org.instagramUrl);
    //delete nwOrg.instagramUrl;
  }
  if (org?.youtubeUrl) {
    //nwOrg.sameAs.push(org.youtubeUrl);
    // @ts-ignore
    org2.sameAs.push(org.youtubeUrl);
    //delete nwOrg.youtubeUrl;
  }
  if (org?.linkedInUrl) {
    //nwOrg.sameAs.push(org.linkedInUrl);
    // @ts-ignore
    org2.sameAs.push(org.linkedInUrl);
    //delete nwOrg.linkedInUrl;
  }

  //delete nwOrg.servicesSchema;

  if (org && org.contactPoints) {
    org2.contactPoint = [];

    org?.contactPoints?.map((cp: any) => {
      //delete cp.sys;
      //delete cp.metadata;
      //delete cp.fields.internalName;

      const cp3: ContactPoint = {
        "@type": "ContactPoint",
        contactType: cp.fields.contactType,
        telephone: cp.fields.telephone,
        email: cp.fields.email,
      };
      const cp2 = { ...cp.fields };
      cp3.availableLanguage = [];
      cp2.availableLanguage = [];

      if (typeof cp.fields.availableLanguages === "string") {
        cp2.availableLanguage.push(cp.fields.availableLanguages);
        // @ts-ignore
        cp3.availableLanguage.push(cp.fields.availableLanguages);
      }
      delete cp2.availableLanguages;
      //nwOrg.contactPoint.push(cp2);
      // @ts-ignore
      org2.contactPoint.push(cp3);
    });
  }

  return org2;
};
