import { useSettingsQuery } from "@src/components/molecules/Settings/__generated/Settings.generated";
import { QueryClient } from "@tanstack/react-query";
import { useFaqSchemaQuery } from "@src/components/schemas/FaqSchema/__generated/FaqSchema.generated";
import { renderDocumentToHtmlString } from "@src/components/atoms/TextBlock";
import { FaqEntryType } from "@src/components/schemas/FaqSchema/FaqSchema";

export const processFaqSchema = (pageCollection: any): FaqEntryType[] => {
  let entries;
  try {
    let content: any = pageCollection?.items.map((item: any) => {
      return item?.content;
    });

    if (content && content.length > 0) {
      const mappedArray = content[0]?.contentCollection?.items
        .filter((item: any) => {
          return Object.keys(item).length > 0;
        })
        .flat();

      if (mappedArray && mappedArray.length > 0) {
        const filteredArray = mappedArray.map((item: any) => {
          return item?.entriesCollection?.items;
        });

        if (filteredArray && filteredArray.length > 0) {
          entries = filteredArray.flat().map((x: any) => {
            let answer = renderDocumentToHtmlString(x?.answer?.json, {});
            return {
              question: x?.question,
              answer,
            };
          });
        }
      }
    }
  } catch (error) {
    console.warn(error);
  }

  return entries;
};

export const getFaqSchemaGql = async ({
  queryClient,
  preview,
  slug,
}: {
  queryClient: QueryClient;
  preview: boolean;
  slug: string | undefined;
}): Promise<FaqEntryType[]> => {
  let entries;
  try {
    // prefetch queries
    await queryClient.prefetchQuery(
      useFaqSchemaQuery.getKey({ preview, slug }),
      // @ts-ignore
      useFaqSchemaQuery.fetcher({ preview, slug })
    );

    const { pageCollection } = await useFaqSchemaQuery.fetcher({
      preview,
      slug,
    });

    const entries = processFaqSchema(pageCollection);
  } catch (error) {
    console.warn(error);
  }

  return entries || [];
};
