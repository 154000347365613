import { Entry, EntryCollection } from "contentful";
import stringify from "fast-safe-stringify";
import { IPage, IPageFields } from "src/types/generated/contentful";

type IPageType = { fields: IPageFields; contentTypeId: "page" };

export const parsePage = (pages: EntryCollection<IPageType>): IPage => {
  // Kill circular references
  const str = stringify(pages) as string;
  return JSON.parse(str);
};
