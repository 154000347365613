// @ts-nocheck
import * as Types from '../../../../lib/__generated/graphql.types';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { myFetcher } from '@src/lib/fetchConfig.dtc';
export type ShareButtonFieldsFragment = { __typename: 'ShareButton', title?: string | null, description?: string | null, shareType?: Array<string | null> | null, quote?: string | null, hashtags?: Array<string | null> | null, hashtag?: string | null, via?: string | null, related?: Array<string | null> | null, appId?: string | null, image?: { __typename?: 'Asset', url?: string | null, width?: number | null, height?: number | null } | null };

export type ShareButtonsDefaultFragment = { __typename: 'ShareButtonsContentCollection', items: Array<(
    { __typename: 'ShareButton' }
    & ShareButtonFieldsFragment
  ) | null> };

export type ShareButtonsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShareButtonsQuery = { __typename?: 'Query', settingsCollection?: { __typename: 'SettingsCollection', items: Array<{ __typename: 'Settings', shareButtonDefault?: { __typename: 'ShareButtons', contentCollection?: (
          { __typename?: 'ShareButtonsContentCollection' }
          & ShareButtonsDefaultFragment
        ) | null } | null } | null> } | null };

export const ShareButtonFieldsFragmentDoc = `
    fragment ShareButtonFields on ShareButton {
  __typename
  title
  description
  shareType
  image {
    url
    width
    height
  }
  quote
  hashtags
  hashtag
  via
  related
  appId
}
    `;
export const ShareButtonsDefaultFragmentDoc = `
    fragment ShareButtonsDefault on ShareButtonsContentCollection {
  __typename
  items {
    __typename
    ...ShareButtonFields
  }
}
    `;
export const ShareButtonsDocument = `
    query ShareButtons {
  settingsCollection(preview: false, limit: 1) {
    __typename
    items {
      __typename
      shareButtonDefault {
        __typename
        contentCollection {
          ...ShareButtonsDefault
        }
      }
    }
  }
}
    ${ShareButtonsDefaultFragmentDoc}
${ShareButtonFieldsFragmentDoc}`;
export const useShareButtonsQuery = <
      TData = ShareButtonsQuery,
      TError = unknown
    >(
      variables?: ShareButtonsQueryVariables,
      options?: UseQueryOptions<ShareButtonsQuery, TError, TData>
    ) =>
    useQuery<ShareButtonsQuery, TError, TData>(
      variables === undefined ? ['ShareButtons'] : ['ShareButtons', variables],
      myFetcher<ShareButtonsQuery, ShareButtonsQueryVariables>(ShareButtonsDocument, variables),
      options
    );

useShareButtonsQuery.getKey = (variables?: ShareButtonsQueryVariables) => variables === undefined ? ['ShareButtons'] : ['ShareButtons', variables];
;

useShareButtonsQuery.fetcher = (variables?: ShareButtonsQueryVariables, options?: RequestInit['headers']) => myFetcher<ShareButtonsQuery, ShareButtonsQueryVariables>(ShareButtonsDocument, variables, options);