import { Entry } from "contentful";
import safeStringify from "fast-safe-stringify";

import { PageContentType } from "./constants";
import { parsePage } from "./pageParsers";
// import { Locale } from './translations'
import deepOmit from "src/lib/api-util/deepOmit";
import getClient from "src/services/contentful/contentful";

import { resolveExternalEntries } from "src/services/contentful/externalResources";
import { IPageFields } from "src/types/generated/contentful";

export type GetPageParams = {
  slug?: string | string[] | undefined;
  // locale: Locale
  pageContentType: string;
  subPageContentType?: string;
  preview?: boolean;
  query?: any;
};

const getPageQuery = (params: GetPageParams) => ({
  limit: 1,
  include: 10,
  select: "sys,fields,metadata",
  // locale: params.locale,
  "fields.slug": params.slug,
  content_type: PageContentType,
});

type IPageType = { fields: IPageFields; contentTypeId: "page" };
// fix the entry-hyperlinks to return only the slug
const fixCircularDependencies = (obj: any) => {
  // iterate through values
  for (let i in obj) {
    if (obj[i] === "entry-hyperlink") {
      //console.log("entry-hyperlink", safeStringify(obj));
      //delete obj?.data?.fields?.content;
      delete obj?.data?.target?.fields?.content;
    } else {
      if (
        typeof obj[i] === "object" &&
        i !== "sys" &&
        i !== "tags" &&
        i !== "metadata" &&
        i !== "contentType" &&
        i !== "target"
      ) {
        //console.log(i, typeof obj[i], obj[i]);
        fixCircularDependencies(obj[i]);
      }
    }
  }
};

export async function getPage(
  params: GetPageParams
): Promise<Entry<IPageType>> {
  let c = null;

  const preview = params?.preview || false;
  try {
    const query = getPageQuery(params);
    const data = await getClient({ preview }).getEntries(query);

    //console.log(JSON.stringify(data.items[0]));

    //    const fs = require("fs");

    // fs.writeFile("/tmp/test", safeStringify(data.items[0]), function (err) {
    //   if (err) {
    //     return console.error(err);
    //   }
    //   console.error("The file was saved!");
    // });

    // // Or
    // fs.writeFileSync("/tmp/test-sync", "Hey there!");

    c = JSON.parse(safeStringify(data.items[0]));
    fixCircularDependencies(c);

    c = deepOmit(c);
    c = await resolveExternalEntries(c, preview);
  } catch (e) {
    // TODO: Sentry
    console.error(e);
  }

  return c;
  // return page
}

export async function getPagesOfType(params: GetPageParams) {
  const {
    pageContentType,
    preview,
    slug,
    query,
    // locale
  } = params;
  try {
    const client = getClient({ preview });

    const options: any = {
      limit: 100,
      // locale,
      ...query,
      content_type: PageContentType,
      // "fields.content.fields.slug": "pageContentType",
    };

    if (pageContentType) {
      options["fields.content.sys.contentType.sys.id"] = pageContentType;
    }

    if (slug) {
      options["fields.slug"] = "home";
    }

    const { items: pages } = await client.getEntries(options);

    return pages ? pages.map((page: any) => parsePage(page)) : [];
  } catch (e) {
    console.error(e);
    return [];
  }
}
