// @ts-nocheck
import * as Types from '../../../../lib/__generated/graphql.types';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { myFetcher } from '@src/lib/fetchConfig.dtc';
export type OrganizationQueryVariables = Types.Exact<{
  entryId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type OrganizationQuery = { __typename?: 'Query', organizationCollection?: { __typename?: 'OrganizationCollection', items: Array<{ __typename?: 'Organization', name?: string | null, streetAddress?: string | null, addressLocality?: string | null, addressRegion?: string | null, addressCountry?: string | null, postalCode?: string | null, telephone?: string | null, fax?: string | null, url?: string | null, servicesSchema?: any | null, facebookUrl?: string | null, twitterUrl?: string | null, linkedInUrl?: string | null, instagramUrl?: string | null, sys: { __typename?: 'Sys', id: string }, logo?: { __typename?: 'Asset', title?: string | null, description?: string | null, width?: number | null, height?: number | null, url?: string | null } | null, contactPointsCollection?: { __typename?: 'OrganizationContactPointsCollection', items: Array<{ __typename?: 'ContactPoint', telephone?: string | null, contactOption?: string | null, contactTypes?: Array<string | null> | null, ttNumber?: string | null, alphaNumericTelephoneEquivalent?: string | null } | null> } | null } | null> } | null };


export const OrganizationDocument = `
    query Organization($entryId: String) {
  organizationCollection(where: {sys: {id: $entryId}}, limit: 1) {
    items {
      sys {
        id
      }
      name
      streetAddress
      addressLocality
      addressRegion
      addressCountry
      postalCode
      telephone
      fax
      url
      servicesSchema
      logo {
        title
        description
        width
        height
        url
      }
      facebookUrl
      twitterUrl
      linkedInUrl
      twitterUrl
      instagramUrl
      contactPointsCollection(limit: 50) {
        items {
          telephone
          contactOption
          contactTypes
          ttNumber
          alphaNumericTelephoneEquivalent
        }
      }
    }
  }
}
    `;
export const useOrganizationQuery = <
      TData = OrganizationQuery,
      TError = unknown
    >(
      variables?: OrganizationQueryVariables,
      options?: UseQueryOptions<OrganizationQuery, TError, TData>
    ) =>
    useQuery<OrganizationQuery, TError, TData>(
      variables === undefined ? ['Organization'] : ['Organization', variables],
      myFetcher<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, variables),
      options
    );

useOrganizationQuery.getKey = (variables?: OrganizationQueryVariables) => variables === undefined ? ['Organization'] : ['Organization', variables];
;

useOrganizationQuery.fetcher = (variables?: OrganizationQueryVariables, options?: RequestInit['headers']) => myFetcher<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, variables, options);