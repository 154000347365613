import { useOrganizationQuery } from "@src/components/atoms/OrgContactInfo/__generated/Organization.generated";
import getClient, { getAccessTokens } from "../contentful";
import path from "path";

function asyncStringify(str: string): Promise<string> {
  return new Promise((resolve, reject) => {
    resolve(JSON.stringify(str));
  });
}

const getEntries = async ({
  entryId,
  client,
}: {
  entryId: string;
  client: any;
}) => {
  return new Promise((resolve, reject) => {
    //@ts-ignore
    client
      .getEntries<any>({
        // content_type: "organization",
        "sys.id": entryId,
        include: 5,
        // select: "fields",
        limit: 1,
      })
      .then((x: any) => {
        resolve(x.items[0]);
      })
      .catch((err: any) => {
        //console.log(err);
        reject(err);
      });
  });
};

const cache: Cache = {};

export const getExternalResourceEntry = async ({
  urn,
  preview,
}: {
  urn: string;
  preview: boolean;
}): Promise<any> => {
  if (cache[urn]) {
    // Return the cached result if it exists
    return cache[urn];
  }
  let entry;
  const ids = urn.split("/");
  const spaceId = ids[1];
  const entryId = ids[3];

  // process entry:: SCHEMAS: ORGANIZATION
  if (entryId === "4JkkjIsm3T8ga9RWnh7Xz5") {
    const org = await useOrganizationQuery.fetcher({
      entryId,
      // @ts-ignore

      spaceId,
      preview,
    });

    entry = org?.organizationCollection?.items[0];
  }

  cache[urn] = entry;

  return entry;
};

type Cache = Record<string, any>;

// export const resolveExternalEntries = async (entry: any): Promise<any> => {
//   let newEntry = JSON.parse(await asyncStringify(entry));
//   if (Array.isArray(entry)) {
//     return Promise.all(
//       newEntry.map(async (e: any, i: number) => {
//         return await replaceUrnWithExternalResource(e);
//       })
//     );
//   } else {
//     return await replaceUrnWithExternalResource(entry);
//   }
// };

// export const replaceUrnWithExternalResource = async (
//   entry: any
// ): Promise<any> => {
//   let newEntry = JSON.parse(await asyncStringify(entry));

//   await Promise.all(
//     Object.entries(entry).map(async (obj: any) => {
//       const key = obj[0];
//       if (obj[1]?.sys?.urn) {
//         const urn = obj[1]?.sys?.urn;
//         const entry = await getExternalResourceEntry({ urn });
//         newEntry[key] = entry;
//       } else if (
//         (Object.keys(entry[key]).length > 0 && key === "items") ||
//         key === "fields" ||
//         key === "content" ||
//         key === "richContent"
//       ) {
//         newEntry[key] = await resolveExternalEntries(entry[key]);
//       }
//       return newEntry[key];
//     })
//   );

//   return newEntry;
// };

export const resolveExternalEntries = async (
  entry: any,
  preview: boolean
): Promise<any> => {
  const jsonString = await asyncStringify(entry);

  const regex = /"urn":"([^"]+)"/g;
  let replacedJsonString = jsonString;
  let match;

  const matches = Array.from(jsonString.matchAll(regex));
  if (matches.length > 0) {
    for (const match of matches) {
      const urn = match[1];
      const urnEscaped = urn.replaceAll("/", "\\/");
      //const parentRegex = new RegExp(`{"sys":.*"urn":"${urnEscaped}"}}`, "g");
      const parentRegex = new RegExp(
        `"organization":{"sys":{([^}]*?)"urn":"${urnEscaped}"}}`,
        "g"
      );

      const externalEntry = await getExternalResourceEntry({ urn, preview });

      const replacement = `"organization":${JSON.stringify(externalEntry)}`;
      replacedJsonString = replacedJsonString.replace(parentRegex, replacement);
    }
  }

  try {
    return JSON.parse(replacedJsonString);
  } catch (e) {
    console.warn(e);
    return entry;
  }
};
