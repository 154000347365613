// @ts-nocheck
import * as Types from '../../../../lib/__generated/graphql.types';

import { ShareButtonsDefaultFragment, ShareButtonFieldsFragment } from '../../ShareButtons/__generated/ShareButtons.generated';
import { ShareButtonsDefaultFragmentDoc, ShareButtonFieldsFragmentDoc } from '../../ShareButtons/__generated/ShareButtons.generated';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { myFetcher } from '@src/lib/fetchConfig.dtc';
export type OrgFragment = { __typename?: 'ResourceLink', sys: { __typename?: 'ResourceSys', urn: string } };

export type WebSettingsFragment = { __typename?: 'Settings', siteTitle?: string | null, siteDescription?: string | null, blogTitle?: string | null, blogDescription?: string | null, siteLogo?: { __typename?: 'Asset', title?: string | null, description?: string | null, contentType?: string | null, fileName?: string | null, size?: number | null, url?: string | null, width?: number | null, height?: number | null } | null, favicon?: { __typename?: 'Asset', title?: string | null, description?: string | null, contentType?: string | null, fileName?: string | null, size?: number | null, url?: string | null, width?: number | null, height?: number | null } | null, socialBanner?: { __typename?: 'Asset', title?: string | null, description?: string | null, contentType?: string | null, fileName?: string | null, size?: number | null, url?: string | null, width?: number | null, height?: number | null } | null, organization?: (
    { __typename?: 'ResourceLink' }
    & OrgFragment
  ) | null, shareButtonDefault?: { __typename?: 'ShareButtons', contentCollection?: (
      { __typename?: 'ShareButtonsContentCollection' }
      & ShareButtonsDefaultFragment
    ) | null } | null };

export type SettingsQueryVariables = Types.Exact<{
  preview?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type SettingsQuery = { __typename?: 'Query', settingsCollection?: { __typename?: 'SettingsCollection', items: Array<(
      { __typename?: 'Settings' }
      & WebSettingsFragment
    ) | null> } | null };

export const OrgFragmentDoc = `
    fragment Org on ResourceLink {
  sys {
    urn
  }
}
    `;
export const WebSettingsFragmentDoc = `
    fragment WebSettings on Settings {
  siteTitle
  siteDescription
  siteLogo {
    title
    description
    contentType
    fileName
    size
    url
    width
    height
  }
  favicon {
    title
    description
    contentType
    fileName
    size
    url
    width
    height
  }
  socialBanner {
    title
    description
    contentType
    fileName
    size
    url
    width
    height
  }
  blogTitle
  blogDescription
  organization {
    ...Org
  }
  shareButtonDefault {
    contentCollection {
      ...ShareButtonsDefault
    }
  }
}
    `;
export const SettingsDocument = `
    query Settings($preview: Boolean) {
  settingsCollection(preview: $preview, limit: 1) {
    items {
      ...WebSettings
    }
  }
}
    ${WebSettingsFragmentDoc}
${OrgFragmentDoc}
${ShareButtonsDefaultFragmentDoc}
${ShareButtonFieldsFragmentDoc}`;
export const useSettingsQuery = <
      TData = SettingsQuery,
      TError = unknown
    >(
      variables?: SettingsQueryVariables,
      options?: UseQueryOptions<SettingsQuery, TError, TData>
    ) =>
    useQuery<SettingsQuery, TError, TData>(
      variables === undefined ? ['Settings'] : ['Settings', variables],
      myFetcher<SettingsQuery, SettingsQueryVariables>(SettingsDocument, variables),
      options
    );

useSettingsQuery.getKey = (variables?: SettingsQueryVariables) => variables === undefined ? ['Settings'] : ['Settings', variables];
;

useSettingsQuery.fetcher = (variables?: SettingsQueryVariables, options?: RequestInit['headers']) => myFetcher<SettingsQuery, SettingsQueryVariables>(SettingsDocument, variables, options);